<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
  >
    <b-row>
      <form-input
        label="Package Name"
        v-model="form.name"
        v-validate="`required`"
        data-vv-as="Package Name"
        :col="12"
      />
      <form-input
        label="Detail"
        v-model="form.detail"
        v-validate="`required`"
        data-vv-as="Detail"
        :col="12"
        textarea
      />

      <form-input-currency
        label="Total toll"
        v-model="form.amount"
        v-validate="`required`"
        data-vv-as="Total toll"
        :col="6"
        :decimalLength="0"
      />
      <form-input-currency
        label="Price"
        v-model="form.price"
        v-validate="`required`"
        data-vv-as="Price"
        :col="6"
      />
      <form-upload-image
        label="Upload Image"
        :col="12"
        :max="3"
        ref="uploadImage"
        :images="form.image_datas"
      />
    </b-row>
  </form-modal>
</template>
<script>
import { stringify } from "postcss"
import { mapState } from "vuex"
export default {
  components: {
    PackageFormList: () => import("./PackageFormList.vue"),
  },
  computed: {
    ...mapState("PackageForm", ["form"]),

    _title() {
      return this.form.id ? "Edit Package" : "Create Package"
    },
  },

  methods: {
    show(data = "") {
      this.clearForm()
      if (data) {
        Object.keys(data).forEach((row) => {
          this.form[row] = data[row]
        })
      }
      this.switch("show")
    },
    async handleSubmit(e) {
      try {
        e.preventDefault()

        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }

        const resp = await this.api.createOrUpdate(
          "/api/packages",
          this.form,
          "Packages"
        )
        await this.$refs.uploadImage.uploadImage(resp["id"], "packageDatas")

        this.$emit("reloading")
        this.switch("hide")
      } catch (error) {
        this.alert.errorCode422(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
